import { createRouter, createWebHistory } from "vue-router"
import { setupLayouts } from 'virtual:generated-layouts'
import { routes as rawRoutes } from 'vue-router/auto-routes'
import { type App } from "vue"
import { traverse } from "$commons/components/traverse"

const routes = rawRoutes.filter(it => it.meta?.namespace == "agents")
traverse(routes, (route) => {
  if (!route.component) {
    return
  }

  route.meta ??= {}

  if (route.meta.layout) {
    route.meta.layout = `agents/layouts/${route.meta.layout}`
  } else {
    route.meta.layout = 'agents/layouts/default'
  }
})
const routes_with_layout = setupLayouts(routes)

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    return { top: 0, left: 0 }
  },
  routes: routes_with_layout
})

export default function(app: App) {
  app.config.globalProperties.router = router
  app.use(router)
}
