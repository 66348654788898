import "reflect-metadata"
import './assets/utilities.css'
import './assets/style.css'

import '$commons/vendor/fontawesome'

import { createApp } from 'vue'
import AppTemplate from './AppTemplate.vue'

const app = createApp(AppTemplate)

import * as initializers from './initializers'
for (const key in initializers) {
  initializers[key](app)
}

app.mount('#app')
